import React from 'react';
import {IndexContent} from '../../components/indexContent';

function Index() {
    return (
        <IndexContent lang="en" />
    );
}
 
export default Index;
